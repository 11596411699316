import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo.js"

// import Box from '../components/box';
// import Head from '../components/head';
// import Registration from '../components/registration';
// import Registration from '../components/forms/reports/risk-report';
import Registration from "../components/forms/reports/Form"

import SubscribeCTA from "../components/subscribe-cta"

import { Link } from "gatsby"
const RiskReport = () => (
  <div>
    <SEO title="Request Report" />
    <Layout>
      <div className="jumbotron page-header">
        <div className="container">
          <h1 className="display-32">Get Assessments for your Organization</h1>
          <h2 className="display-32">
            Get key insights today.{" "}
            <span style={{ color: "#007bff" }}>Focus on what matters.</span>
          </h2>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-8  content">
            <div className="container container-fluid">
              <div className="row-fluid-wrapper row-depth-1 row-number-1 ">
                <div className="row-fluid ">
                  <div
                    className="span12 widget-span widget-type-custom_widget "
                    data-widget-type="custom_widget"
                    data-x="0"
                    data-w="12"
                  >
                    <div
                      id="hs_cos_wrapper_module_1559559069417869"
                      className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                      data-hs-cos-general-type="widget"
                      data-hs-cos-type="module"
                    >
                      <div className="lp-hero-mod" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row-fluid-wrapper row-depth-1 row-number-3 ">
                <div className="row-fluid ">
                  <div
                    className="span12 widget-span widget-type-cell main-body-content"
                    data-widget-type="cell"
                    data-x="0"
                    data-w="12"
                  >
                    <div className="row-fluid-wrapper row-depth-1 row-number-4 ">
                      <div className="row-fluid ">
                        <div
                          className="span12 widget-span widget-type-cell page-center rrLpbox"
                          data-widget-type="cell"
                          data-x="0"
                          data-w="12"
                        >
                          <div className="row-fluid-wrapper row-depth-1 row-number-5 ">
                            <div className="row-fluid ">
                              <div
                                className="span8 widget-span widget-type-widget_container lp-left-col"
                                data-widget-type="widget_container"
                                data-x="0"
                                data-w="8"
                              >
                                <span
                                  id="hs_cos_wrapper_module_1559560058107891"
                                  className="hs_cos_wrapper hs_cos_wrapper_widget_container hs_cos_wrapper_type_widget_container"
                                  data-hs-cos-general-type="widget_container"
                                  data-hs-cos-type="widget_container"
                                >
                                  <div
                                    id="hs_cos_wrapper_widget_1559560045578888"
                                    className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                                    data-hs-cos-general-type="widget"
                                    data-hs-cos-type="module"
                                  >
                                    <div className="full-width-content-mod paddingtb30 max-content-width  ">
                                      <div className="page-center">
                                        <div className="section-header">
                                          <h2>
                                            Understand Business &amp; IT Risks
                                          </h2>
                                        </div>

                                        <div className="section-content bottom-divider">
                                          <p>
                                            <strong>
                                              What is the Risk Report?
                                            </strong>
                                            <br />
                                            This is a report providing a summary
                                            of an organization's privacy and
                                            data protection posture with
                                            influencing risk factors.
                                            <br />
                                            <br />
                                            <strong>
                                              How can I use this report?
                                            </strong>
                                            <br />
                                            For a company, privacy and data
                                            protection risk today standards for
                                            customers and consumers as well as
                                            regulators, if not properly handled
                                            could lead to regulatory
                                            enforcement, law suits, financial
                                            liabilites, loss of brand trust and
                                            loss of revenues not to mention
                                            business to competitors. What we
                                            provide is done through UVision's
                                            objective, external discovery and
                                            analysis. You can also opt in to be
                                            even more transparent to be even
                                            more compliant with standards for
                                            data protection with legal
                                            consequences. Your risk report is
                                            just the start of improving your
                                            privacy risk.
                                          </p>
                                          <p>
                                            <strong>
                                              What can I expect from the
                                              Assessment Report?
                                            </strong>
                                          </p>
                                          <ul>
                                            <li>
                                              <p>
                                                Access a snapshot report for a
                                                limited time
                                              </p>
                                            </li>
                                            <li>
                                              <p>
                                                Know what the findings are and
                                                what they mean in terms of risks
                                                across multiple contexts
                                              </p>
                                            </li>
                                            <li>
                                              <p>
                                                Learn how an organization's
                                                privacy performance compares to
                                                competitors &amp; peers in the
                                                relevant industry
                                              </p>
                                            </li>
                                            <li>
                                              <p>
                                                Learn how to use the score to
                                                influence corrective action with
                                                risk prioritized data based on
                                                issue severity.
                                              </p>
                                            </li>
                                          </ul>
                                          <p>
                                            Interested in deeper data protection
                                            and privacy risk insights on
                                            organization and third-parties?
                                          </p>
                                          <p>
                                            <Link
                                              to="/lets-talk/"
                                              // rel="noopener"
                                              // target="_blank"
                                            >
                                              Request a free intro call
                                            </Link>{" "}
                                            with our team today!
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    id="hs_cos_wrapper_widget_1582320669771"
                                    className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                                    data-hs-cos-general-type="widget"
                                    data-hs-cos-type="module"
                                  />
                                </span>
                              </div>
                              <div
                                className="span4 widget-span widget-type-cell lp-right-col"
                                data-widget-type="cell"
                                data-x="8"
                                data-w="4"
                              />
                            </div>
                          </div>

                          <div className="row-fluid-wrapper row-depth-1 row-number-6 ">
                            <div className="row-fluid ">
                              <div
                                className="span12 widget-span widget-type-widget_container "
                                data-widget-type="widget_container"
                                data-x="0"
                                data-w="12"
                              >
                                <span
                                  id="hs_cos_wrapper_module_1573039922314103"
                                  className="hs_cos_wrapper hs_cos_wrapper_widget_container hs_cos_wrapper_type_widget_container"
                                  data-hs-cos-general-type="widget_container"
                                  data-hs-cos-type="widget_container"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <br />
            <br />
            {/* <Registration/> */}
            <SubscribeCTA title="Request Assessment" />
          </div>{" "}
        </div>{" "}
      </div>
    </Layout>
  </div>
)

export default RiskReport
